import React, { useRef, useState } from 'react';

import { useTranslation } from 'next-i18next';
import { useIsomorphicLayoutEffect } from '@/hooks/useIsomorphicLayoutEffect';
import {
  ButtonContainer,
  ClickableArea,
  FOCUS_BAR_INSET_PX,
  FocusBar,
  SwitchBeforeAfterVariant,
} from './SwitchBeforeAfter.styles';

type FocusBarStyle = {
  left: number;
  width: number;
};

interface SwitchBeforeAfterProps {
  showBefore: boolean;
  toggleEvent: () => void;
  variant?: SwitchBeforeAfterVariant;
}

export const SwitchBeforeAfter = ({
  toggleEvent,
  showBefore,
  variant = 'monochrome',
  ...rest
}: SwitchBeforeAfterProps) => {
  const { t } = useTranslation('enhancementToggleButton');

  const [focusBarStyle, setFocusBarStyle] = useState<FocusBarStyle>();
  const refBeforeButton = useRef<HTMLDivElement>(null);
  const refAfterButton = useRef<HTMLDivElement>(null);

  /**
   * On the production build the style props of the "focus bar"
   * is sometimes not correctly defined on the first render.
   * This layout effect makes sure it is calculated after all DOM mutations are processed.
   */
  useIsomorphicLayoutEffect(() => {
    let style: FocusBarStyle | undefined = undefined;

    if (refBeforeButton.current && refAfterButton.current) {
      const width = showBefore
        ? refBeforeButton.current.offsetWidth
        : refAfterButton.current.offsetWidth;

      const left = showBefore
        ? FOCUS_BAR_INSET_PX
        : refAfterButton.current.offsetLeft;

      style = {
        width: width - FOCUS_BAR_INSET_PX,
        left,
      };
    }

    setFocusBarStyle(style);
  }, [showBefore]);

  return (
    <ButtonContainer
      onClick={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
      {...rest}
    >
      <ClickableArea
        ref={refBeforeButton}
        onClick={toggleEvent}
        $isActive={showBefore}
        $variant={variant}
      >
        {t('before')}
      </ClickableArea>
      <ClickableArea
        ref={refAfterButton}
        onClick={toggleEvent}
        $isActive={!showBefore}
        $variant={variant}
      >
        {t('after')}
      </ClickableArea>
      <FocusBar style={focusBarStyle} />
    </ButtonContainer>
  );
};
