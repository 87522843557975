import useSWR, { mutate } from 'swr';
import queryString from 'query-string';
import { MediaCollection } from '@/types/Media/MediaCollection';
import { MSApiError } from '@/types/ApiError';
import { useAuth0Credentials } from '@/hooks/use-auth0-credentials';
import {
  GetTokenAsync,
  msApiDelete,
  msApiGet,
  msApiPost,
  SWRMSApiKey,
} from '../clients';

export interface GetMediaParameters {
  search?: string;
  albumName?: string;
  albumUuid?: string;
  uploadDate?: 'asc' | 'desc';
  uploadDateBefore?: string;
  uploadDateAfter?: string;
  shootingDate?: 'asc' | 'desc';
  page?: number;
  itemsPerPage?: number;
}

/**
 * GET /customer/media
 * List all media
 */
export const useMedias = (params: GetMediaParameters) => {
  const { userId, workspaceId, getTokenAsync } = useAuth0Credentials();

  const paramsString = queryString.stringify(params, {
    arrayFormat: 'bracket',
  });
  const apiUrl = `customer/media?${paramsString}`;

  const { data, error } = useSWR<MediaCollection, MSApiError, SWRMSApiKey>(
    [apiUrl, workspaceId, userId],
    ([path, wsId]) => msApiGet(path, getTokenAsync, wsId),
    {
      errorRetryCount: 3,
      revalidateOnMount: true,
    }
  );

  return {
    data,
    isLoading: !error && !data,
    isError: !!error,
  };
};

/**
 * Revalidate the media list of a specific album, if no page is provided, the first page will be revalidated
 */
export function mutateMediasOfAlbum(uuid: string, page: number = 1) {
  mutate((key: SWRMSApiKey) => {
    if (key === null || !Array.isArray(key)) return false;

    const [path] = key;

    return (
      path.startsWith('customer/media') &&
      path.includes(`albumUuid=${uuid}`) &&
      path.includes(`page=${page}`)
    );
  }).catch((err) => {
    throw new Error(`Error while mutating medias of album ${uuid}: ${err}`);
  });
}

/**
 * POST /customer/media/{uuid}/moderate/validate
 * Approve a media when it is in manual-check state
 */
export const approveMedia = async (
  mediaUuid: string,
  getTokenAsync: GetTokenAsync,
  workspaceId: string | null
) => {
  return msApiPost(
    `customer/media/${mediaUuid}/moderate/validate`,
    getTokenAsync,
    workspaceId,
    { json: {} }
  );
};

/**
 * POST /customer/media/{uuid}/moderate/reject
 * Reject a media when it is in manual-check state
 */
export const rejectMedia = async (
  mediaUuid: string,
  refusalReasonUuid: string | undefined,
  getTokenAsync: GetTokenAsync,
  workspaceId: string | null
) => {
  return msApiPost(
    `customer/media/${mediaUuid}/moderate/reject`,
    getTokenAsync,
    workspaceId,
    {
      json: { refusalReasonUuid },
    }
  );
};

type RequestArchiveDTO = {
  locale: string;
  fileName?: string;
  watermark: boolean;
  albumList: Array<string>;
  mediaList: Array<string>;
};

/**
 * POST /media/archive
 * Request the creation of an archive with the selected albums/media
 */
export async function requestArchive(
  payload: RequestArchiveDTO,
  getTokenAsync: GetTokenAsync,
  workspaceId: string
) {
  return msApiPost('media/archive', getTokenAsync, workspaceId, {
    json: payload,
  });
}

export type SaveOrigin =
  | 'eraser'
  | 'home_staging'
  | 'sky_replacement'
  | 'wall_painting'
  | 'quick_edit';

type SaveMediaDTO = {
  file: Blob;
  fileName: string;
  origin: SaveOrigin;
};
/**
 * POST /customer/media/{uuid}/version
 * Request the creation of a new version of a media
 */
export async function saveAsNewVersion(
  mediaUuid: string,
  formDataPayload: SaveMediaDTO,
  getTokenAsync: GetTokenAsync,
  workspaceId: string | null
) {
  const formData = new FormData();
  formData.append('file', formDataPayload.file, formDataPayload.fileName);
  formData.append('origin', formDataPayload.origin);

  return msApiPost(
    `customer/media/${mediaUuid}/version`,
    getTokenAsync,
    workspaceId,
    {
      body: formData,
      timeout: false,
    }
  );
}

/**
 * POST /customer/media/from-version/{uuid}
 * Create a copy based on an existing media version
 */
export async function saveAsNewMedia(
  versionUuid: string,
  formDataPayload: SaveMediaDTO,
  getTokenAsync: GetTokenAsync,
  workspaceId: string | null
) {
  const formData = new FormData();
  formData.append('file', formDataPayload.file, formDataPayload.fileName);
  formData.append('origin', formDataPayload.origin);

  return msApiPost(
    `customer/media/from-version/${versionUuid}`,
    getTokenAsync,
    workspaceId,
    {
      body: formData,
      timeout: false,
    }
  );
}

export type PreUploadMediaResponse = {
  path: string;
  destinationKey: string;
  destinationBucket: string | null;
};

/**
 * POST /media/pre-upload
 * Pre-upload a media
 */
export async function preUploadMedia(
  file: Blob,
  getTokenAsync: GetTokenAsync,
  workspaceId?: string | null
) {
  const formData = new FormData();
  formData.append('file', file);

  return msApiPost<PreUploadMediaResponse>(
    'media/pre-upload',
    getTokenAsync,
    workspaceId,
    {
      body: formData,
      timeout: false,
    }
  );
}

export type MediaIngestionDTO = {
  location: {
    source: 's3';
    path: string;
    bucket?: string;
  };
  ingestionData: {
    accountUuid: string;
    albumReference: string;
    isImportedFromGallery?: boolean;
    workflowUuid: string;
    origin: 'mobile' | 'web';
    originalName: string;
    mediaReference: string;
    editingParameters: Array<string>;
  };
};

/**
 * POST /media/ingestion/batch
 * Ingest a list of media
 */
export async function ingestBatchMedia(
  mediaIngestions: Array<MediaIngestionDTO>,
  getTokenAsync: GetTokenAsync,
  workspaceId?: string | null
) {
  return msApiPost('media/ingestion/batch', getTokenAsync, workspaceId, {
    json: {
      mediaIngestions,
    },
  });
}

export function mutateMedia(uuid: string | undefined) {
  mutate((key: SWRMSApiKey) => {
    if (key === null) return false;

    const [path] = key;

    return path.startsWith('customer/media');
  }).catch((err) => {
    throw new Error(`Error while deleting media ${uuid}: ${err}`);
  });
}
/**
 * DELETE /customer/media/{uuid}
 * Soft delete a specific media
 */

export async function deleteMedia(
  mediaUuid: string,
  getTokenAsync: GetTokenAsync,
  workspaceId: string | null
) {
  const apiUrl = `customer/media/${mediaUuid}/soft-delete`;

  return msApiDelete(apiUrl, getTokenAsync, workspaceId);
}
