import { MobileStepper } from '@mui/material';
import { TwicImg, TwicVideo } from '@twicpics/components/react';
import styled from '@/utils/styled';
import { colors, fontSize, fontWeight, getMeeSpacing } from '@/theme';
import { Dialog } from '@/components/common';
import { DialogText } from '@/components/common/Dialog/Dialog.styles';

export const StyledMobileStepper = styled(MobileStepper)`
  padding-left: 0;

  .MuiMobileStepper-dot {
    background-color: ${colors.primary};
    opacity: 0.5;
    margin-left: 4px;
    margin-right: 4px;
  }
  div .MuiMobileStepper-dotActive {
    // zoom in the active dot
    transform: scale(1.5);
    opacity: 1;
  }
`;

export const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    border-radius: ${getMeeSpacing(1.5)};
    flex-direction: row;
    background-color: ${colors.white};
    min-height: 540px;
  }
`;

export const StyledTwicVideo = styled(TwicVideo)`
  height: 100%;
`;

export const StyledTwicImg = styled(TwicImg)`
  height: 100%;
`;

export const StyledDialogText = styled(DialogText)`
  height: 100%;
`;

export const BetaChip = styled('sup')`
  border-radius: 12px;
  padding: ${getMeeSpacing(0.5, 1)};
  margin-left: ${getMeeSpacing(1)};
  font-size: ${fontSize.small};
  background-color: ${colors.frostyMint};
  color: ${colors.hollyGreen};
  text-transform: uppercase;
  font-weight: ${fontWeight.bold};
`;
