import styled from '@/utils/styled';
import {
  colors,
  fontSize,
  fontWeight,
  getMeeSpacing,
  lineHeight,
} from '@/theme';

export type SwitchBeforeAfterVariant = 'default' | 'monochrome';

export const ButtonContainer = styled('button')`
  position: relative;
  display: flex;
  align-items: center;
  border: unset;
  padding: 0;
  border-radius: 8px;
  background-color: ${colors.crystalBell};
  overflow: hidden;
  height: 32px;
`;

export const ClickableArea = styled('div')<{
  $isActive: boolean;
  $variant?: SwitchBeforeAfterVariant;
}>`
  display: flex;
  height: 100%;
  align-items: center;
  padding: ${getMeeSpacing(0, 1.5)};
  z-index: 1;
  font-size: ${fontSize.small};
  line-height: ${lineHeight.xSmaller};
  transition: color 0.2s ease-in-out;
  cursor: pointer;
  pointer-events: ${({ $isActive }) => ($isActive ? 'none' : 'unset')};

  font-weight: ${({ $isActive }) =>
    $isActive ? fontWeight.semiBold : fontWeight.normal};
`;

export const FOCUS_BAR_INSET_PX = 2;

export const FocusBar = styled('div')`
  position: absolute;
  top: ${FOCUS_BAR_INSET_PX}px;
  bottom: ${FOCUS_BAR_INSET_PX}px;
  box-shadow: 0px 2px 8px 0px rgba(18, 21, 24, 0.2);
  border-radius: 6px;
  /**
    * The width of the focus and the left position are set dynamically,
    * depending on the width of the button and the position of the focus.
   */
  transition: all 0.2s ease-in-out;
  background-color: ${colors.white};
`;
