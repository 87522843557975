import { ComponentType, createContext, useContext } from 'react';
import { Page } from '@/types/Page';

const defaultPage = Page.home;

export type PageContextProps = { page: Page };

const PageContext = createContext<PageContextProps>({
  page: defaultPage,
});

export const withPageContext = (
  WrappedComponent: ComponentType,
  page: Page
): ComponentType => {
  const WithPageContextWrapper = (props: object) => {
    return (
      <PageContext.Provider value={{ page: page }}>
        <WrappedComponent {...props} />
      </PageContext.Provider>
    );
  };

  return WithPageContextWrapper;
};

export const usePageContext = () => useContext(PageContext);
