import { Switch as MuiSwitch } from '@/components/common';
import { getMeeSpacing } from '@/theme';
import styled from '@/utils/styled';

export const Switch = styled(MuiSwitch)`
  display: flex;
  align-items: center;
  width: fit-content;
  .MuiSwitch-track {
    border-radius: 20px;
    margin-left: -5px;
    padding: ${getMeeSpacing(1.5, 2.75)};
  }

  .MuiSwitch-thumb {
    background-color: white;
  }

  .MuiSwitch-switchBase:hover {
    background-color: transparent;
  }
`;
