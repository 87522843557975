import { TwicImg } from '@twicpics/components/react';
import styled from '@/utils/styled';
import { SwitchBeforeAfter } from '@/components/common/SwitchBeforeAfter/SwitchBeforeAfter';

export const Container = styled('div')`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  overflow: hidden;
`;

export const Switch = styled(SwitchBeforeAfter)`
  position: absolute;
  top: 4px;
  left: 4px;
  z-index: 2;
  user-select: none;
`;

export const StyledTwicImg = styled(TwicImg)`
  height: 100%;
`;

export const BeforeTwicImg = styled(StyledTwicImg)<{ $width?: number }>`
  position: absolute;
  right: 0;
  width: ${({ $width }) => `${$width ?? 0}px`};
`;

export type TransitionSpeed = 'default' | 'slow';
export const RevealContainer = styled('div')<{
  $isRevealed: boolean;
  $transitionSpeed: TransitionSpeed;
  $barWidth?: 'default' | 'large';
}>`
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
  width: ${({ $isRevealed }) => ($isRevealed ? 'calc(100% + 2px)' : '0%')};
  transition: ${({ $transitionSpeed }) =>
    $transitionSpeed === 'slow'
      ? 'width 700ms ease-in-out'
      : 'width 400ms ease-in-out'};

  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: ${({ $barWidth = 'default' }) =>
      $barWidth === 'large' ? '8px' : '2px'};
    background-color: white;
  }
`;
