import { Key, MenuTrigger, Separator } from 'react-aria-components';
import { Fragment } from 'react';
import {
  Box,
  CheckIcon,
  CircularProgress,
  DeleteIcon,
  DescriptionOutlinedIcon,
  DownloadMediaIcon,
  DownloadOutlinedIcon,
  EditOutlinedIcon,
  KeyboardArrowDownIcon,
  MoreOptionsIcon,
  SaveOutlinedIcon,
} from '@/components/common';
import { SaveAsACopyIcon } from '@/components/icons/SaveAsACopyIcon';
import {
  StatusContainer,
  StyledDropdownButton,
  StyledMenu,
  StyledMenuItem,
  StyledPopover,
} from './BaseMenu.styles';
import { ButtonIconOnly, ButtonProps } from '../Button/Button';

type AlbumMenuItemsId = 'rename-album' | 'delete-album' | 'listing-generation';
type EditorSaveMenuItemsId = 'save' | 'save-as-copy' | 'download';
type EditorDownloadMenuItemsId =
  | 'download-hd'
  | 'download-compressed'
  | 'download-original';
type DownloadButtonMenuItemsId =
  | 'download-with-watermark'
  | 'download-without-watermark';

type ItemId =
  | AlbumMenuItemsId
  | EditorSaveMenuItemsId
  | EditorDownloadMenuItemsId
  | DownloadButtonMenuItemsId;

const ItemIdToIconMap: Record<ItemId, JSX.Element | undefined> = {
  save: <SaveOutlinedIcon fontSize="small" />,
  'save-as-copy': <SaveAsACopyIcon fontSize="small" color="inherit" />,
  download: <DownloadMediaIcon fontSize="small" />,
  'download-with-watermark': <DownloadMediaIcon fontSize="small" />,
  'download-without-watermark': <DownloadOutlinedIcon fontSize="small" />,
  'rename-album': <EditOutlinedIcon fontSize="inherit" />,
  'delete-album': <DeleteIcon fontSize="inherit" />,
  'listing-generation': <DescriptionOutlinedIcon fontSize="inherit" />,
  'download-hd': <DownloadOutlinedIcon fontSize="small" />,
  'download-compressed': <DownloadOutlinedIcon fontSize="small" />,
  'download-original': <DownloadOutlinedIcon fontSize="small" />,
};

type MenuItem = {
  id: ItemId;
  label: string;
  onClick: () => void;
  disabled?: boolean;
  hasSeparator?: boolean;
};

type MenuButtonTriggerProps = {
  items: Array<MenuItem>;
  button: JSX.Element;
};

export function BaseMenu({ items, button }: MenuButtonTriggerProps) {
  const disabledKeys = items
    .filter((option) => option.disabled === true)
    .map((option) => option.id);

  const handleAction = (id: Key) => {
    const selected = items.find((option) => option.id === id);
    if (selected) {
      selected.onClick();
    }
  };

  return (
    <MenuTrigger>
      {button}
      <StyledPopover placement="bottom end">
        <StyledMenu disabledKeys={disabledKeys} onAction={handleAction}>
          {items.map((option) => (
            <Fragment key={option.id}>
              {Boolean(option.hasSeparator) && (
                <Separator orientation="horizontal" />
              )}
              <StyledMenuItem key={option.id} id={option.id}>
                {ItemIdToIconMap[option.id]}
                <span>{option.label}</span>
              </StyledMenuItem>
            </Fragment>
          ))}
        </StyledMenu>
      </StyledPopover>
    </MenuTrigger>
  );
}

export function MoreOptionsButton({ $variant, ...rest }: ButtonProps) {
  return (
    <ButtonIconOnly $variant={$variant} {...rest}>
      <MoreOptionsIcon />
    </ButtonIconOnly>
  );
}

type DropdownButtonProps = ButtonProps & {
  icon: JSX.Element;
  label: string;
  status?: 'loading' | 'success' | undefined;
  isDisabled?: boolean;
};

export function DropdownButton({
  icon,
  label,
  status,
  isDisabled,
  ...buttonProps
}: DropdownButtonProps) {
  const showStatus = status !== undefined;

  return (
    <StyledDropdownButton isDisabled={isDisabled} {...buttonProps}>
      <Box
        display="inherit"
        alignItems="inherit"
        columnGap={1}
        visibility={showStatus ? 'hidden' : 'visible'}
        maxHeight="100%"
      >
        {icon}
        <span>{label}</span>
        <KeyboardArrowDownIcon fontSize="small" sx={{ marginRight: '-4px' }} />
      </Box>
      {showStatus && (
        <StatusContainer>
          {status === 'loading' ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <CheckIcon fontSize="medium" color="inherit" />
          )}
        </StatusContainer>
      )}
    </StyledDropdownButton>
  );
}
