import styled from '@/utils/styled';
import { colors, getMeeSpacing } from '@/theme';

const WIDTH_LOADING_BAR = '130px';
const WIDTH_LOADING_BAR_MOVING_PART = '60px';

export const Container = styled('div')`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  gap: ${getMeeSpacing(5)};
  justify-content: center;
  align-items: center;
`;
export const LoadingBar = styled('div')`
  width: ${WIDTH_LOADING_BAR};
  height: 4px;
  margin: 0 auto;
  border-radius: 4px;
  background-color: ${colors.whiteSmoke};
  overflow: hidden;
`;

export const MeeroGreenBar = styled('div')`
  width: ${WIDTH_LOADING_BAR_MOVING_PART};
  height: 100%;
  background-color: ${colors.primary};
  border-radius: 4px;
  animation: initial-loading 1.5s ease-in-out infinite;

  @keyframes initial-loading {
    0% {
      transform: translate(calc(-${WIDTH_LOADING_BAR_MOVING_PART} / 2));
    }
    50% {
      transform: translate(
        calc(${WIDTH_LOADING_BAR} - ${WIDTH_LOADING_BAR_MOVING_PART} / 2)
      );
    }
    100% {
      transform: translate(calc(-${WIDTH_LOADING_BAR_MOVING_PART} / 2));
    }
  }
`;
