import { ComponentType, FC, useEffect } from 'react';
import {
  Auth0Context,
  useAuth0,
  withAuthenticationRequired,
  WithAuthenticationRequiredOptions,
} from '@auth0/auth0-react';
import { enqueueSnackbar } from 'notistack';
import { LoadingAppScreen } from '@/components/pages/LoadingAppScreen/LoadingAppScreen';
import { useUserWorkspaceContext } from '@/context/UserWorkspaceContext';
import { Auth0User } from '@/api/auth0/user';
import { useUamWorkspaces } from '@/api/uam';
import { decodeAuth0Token } from '@/api/auth0/token';
import { useCurrentMsUser } from '@/api/meero-suite/users';

/**
 * ```js
 * const MyProtectedComponent = withWorkspaceAuthRequired(MyComponent);
 * ```
 * This Higher Order Component makes sure the component in argument is only accessed by :
 * - A user able to authenticate with Auth0 (it is using Auth0's withAuthenticationRequired internally)
 * - A user assigned to at least one Workspace in UAM (Meero's User Access Management API).
 * If the user validates these 2 conditions, the first workspace is saved in the app context.
 */
export const withWorkspaceAuthRequired = <P extends object>(
  Component: ComponentType<P>,
  options: WithAuthenticationRequiredOptions = {}
): FC<P> => {
  return function WithWorkspaceAuthenticationRequired(props: P): JSX.Element {
    const { context = Auth0Context } = options;
    const { isAuthenticated, getAccessTokenSilently, logout } =
      useAuth0<Auth0User>(context);

    const { currentWorkspaceId, setCurrentWorkspaceId, setAuth0Token } =
      useUserWorkspaceContext();

    const { workspaces, isError: isWsError } = useUamWorkspaces();
    const { msUser, isError: isMsUserError } = useCurrentMsUser();

    useEffect(() => {
      if (isMsUserError || isWsError) {
        enqueueSnackbar({
          variant: 'error',
          title: 'Authentication error',
          message:
            'An error occurred while trying to log you in, please try again later',
          preventDuplicate: true,
        });
      }

      // User is not authenticated, no need to go further
      if (!isAuthenticated) {
        return;
      }

      // User is authenticated, setting up token + workspace id
      async function loginFirstWorkspace(): Promise<void> {
        const token = await getAccessTokenSilently();

        const decodedToken = decodeAuth0Token(token);
        if (decodedToken === null) {
          throw new Error('Invalid token');
        }

        setAuth0Token(token);

        // Return early in case workspaces are not set yet
        if (workspaces == null) return;

        /**
         * Automatic login on the first workspace found
         * - In case the workspace id is not set yet
         * - In case the workspace id already set is not found in the list of workspaces returned from UAM
         */
        if (
          currentWorkspaceId === null ||
          !workspaces.some((ws) => ws.uuid === currentWorkspaceId)
        ) {
          const firstWorkspaceId = workspaces.at(0)?.uuid;
          if (firstWorkspaceId === undefined) {
            setCurrentWorkspaceId(null);
            throw new Error('You are not assigned to any workspace.');
          }
          setCurrentWorkspaceId(firstWorkspaceId);
        }
      }

      loginFirstWorkspace().catch((err: Error) => {
        console.error(err);
        enqueueSnackbar({
          variant: 'error',
          title: 'Could not log in',
          message: err.message,
          preventDuplicate: true,
        });
        setTimeout(
          () =>
            void logout({
              logoutParams: { returnTo: `${window.location.origin}/welcome` },
            }),
          5000
        );
      });
    }, [
      getAccessTokenSilently,
      isAuthenticated,
      isMsUserError,
      isWsError,
      setCurrentWorkspaceId,
      workspaces,
      logout,
      currentWorkspaceId,
      setAuth0Token,
    ]);

    if (!isAuthenticated) {
      options.onRedirecting = () => <LoadingAppScreen />;
      const AuthComponent = withAuthenticationRequired(Component, {
        ...options,
        loginOptions: {
          openUrl() {
            window.location.replace('/welcome');
          },
        },
      });

      return <AuthComponent {...props} />;
    }

    const isWorkspaceSet =
      msUser !== undefined &&
      workspaces !== undefined &&
      currentWorkspaceId !== null;

    return isWorkspaceSet ? <Component {...props} /> : <LoadingAppScreen />;
  };
};
