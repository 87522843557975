export enum Page {
  billingProfile = 'billingProfile',
  contentManagerSetPassword = 'contentManagerSetPassword',
  enhancement = 'enhancement',
  enhancementDetail = 'enhancementDetail',
  home = 'home',
  import = 'import',
  library = 'library',
  libraryDetail = 'libraryDetail',
  welcome = 'welcome',
  mobileStoreRedirect = 'mobileStoreRedirect',
  moderation = 'moderation',
  magicEraser = 'magicEraser',
  overview = 'overview',
  profile = 'profile',
  users = 'users',
  subscription = 'subscription',
  watermark = 'watermark',
  signup = 'signup',
}
