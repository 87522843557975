import styled, { css } from '@/utils/styled';
import { Box, CardActionArea, CheckCircleIcon } from '@/components/common';
import { colors, getMeeSpacing } from '@/theme';

export const StyledCardAction = styled(CardActionArea)`
  position: relative;
  border-radius: 12px;
  width: 90px;
  flex-shrink: 0;

  &.Mui-disabled {
    opacity: 0.4;
  }
`;

export const StyleThumbnail = styled(Box)<{ $isSelected: boolean }>`
  position: relative;
  border-radius: 12px;
  border-width: 4px;
  border-style: solid;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  transition: border 150ms ease-in;

  ${({ $isSelected }) =>
    !$isSelected &&
    css`
      border-color: transparent;
    `}
`;

export const SelectThumbnailCheck = styled(CheckCircleIcon)`
  position: absolute;
  background-color: ${colors.youngNight};
  border-radius: 50%;
  z-index: 10;
  right: 0;
  top: 0;
  transform: translate(33%, -33%);
`;

export const StyleTitle = styled('div')`
  position: absolute;
  padding: ${getMeeSpacing(0.5, 0)};
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(3px);
`;
