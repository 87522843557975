import ProperShotIcon from '@/components/icons/ProperShotIcon';
import {
  Container,
  LoadingBar,
  MeeroGreenBar,
} from './LoadingAppScreen.styles';

export const LoadingAppScreen = () => {
  return (
    <Container aria-label="Loading...">
      <ProperShotIcon width={72} />
      <LoadingBar>
        <MeeroGreenBar />
      </LoadingBar>
    </Container>
  );
};
